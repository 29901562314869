/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "design-system/scss/styles/index.scss";
import axios from "axios";

const fbPageViewEvent = async () => {
  try {
    await axios({
      url: `${process.env.GATSBY_GETCRUISE_PROXY}/fb-page-view`,
      method: "POST",
      data: {
        url: window.location.href
      }
    });
  } catch (error) {}
};

export const onRouteUpdate = ({location, prevLocation}) => {
  const paths = ["/careers/jobs"];

  // Set scroll position to top of the page
  if (paths.indexOf(location.pathname) !== -1) {
    window.scrollTo(0, 0);
  }

  let searchParams = new URLSearchParams(location.search);
  const ghSrcFromSearchParams = searchParams.get("gh_src");

  // Set gh_src tracking code in the localStorage if found
  if (ghSrcFromSearchParams) {
    localStorage.setItem("gh_src", ghSrcFromSearchParams);
  } else if (location.pathname.includes("careers")) {
    // Carried forward gh_src tracking code to jobs related pages
    const ghSrc = localStorage.getItem("gh_src");
    if (ghSrc) {
      searchParams = new URLSearchParams(location.search);
      searchParams.set("gh_src", ghSrc);
      const str = `${window.location.hash}?` + searchParams.toString();
      window.history.replaceState(null, null, str);
    }
  }

  // FB conversion API: call facebook PageView event on route(page) changes
  fbPageViewEvent();
};
